import * as common from './common';
import {
  Nullable,
  BrandConfigurationOverride,
  Location,
  BrandStyle,
} from './types';

export function createJasperLocation(location: Nullable<Location>) {
  if (!location) return null;

  return new common.JasperLocation(location.latitude, location.longitude);
}

export function createBrandConfigurationOverride(
  brandConfigurationOverride: BrandConfigurationOverride
) {
  const playbackSpeedConfiguration = brandConfigurationOverride?.playerConfiguration?.playbackSpeed ?
    new common.JasperPlaybackSpeedConfiguration(
      brandConfigurationOverride?.playerConfiguration?.playbackSpeed?.enabled,
      brandConfigurationOverride?.playerConfiguration?.playbackSpeed?.speeds,
    ) : null;

  const floatPlayerConfiguration = brandConfigurationOverride?.playerConfiguration?.float ?
    new common.JasperFloatPlayerConfiguration(
      brandConfigurationOverride?.playerConfiguration?.float?.enabled,
      brandConfigurationOverride?.playerConfiguration?.float?.position,
      brandConfigurationOverride?.playerConfiguration?.float?.horizontalSpacing,
      brandConfigurationOverride?.playerConfiguration?.float?.verticalSpacing,
      brandConfigurationOverride?.playerConfiguration?.float?.width
    ) : null;

  const upNextConfiguration = brandConfigurationOverride?.playerConfiguration?.upNext ?
    new common.UpNextConfiguration(
      brandConfigurationOverride?.playerConfiguration?.upNext?.displayType,
      brandConfigurationOverride?.playerConfiguration?.upNext?.relatedVideosAsNextContent,
      brandConfigurationOverride?.playerConfiguration?.upNext?.displayThumbnail,
      brandConfigurationOverride?.playerConfiguration?.upNext?.durationInSeconds,
      brandConfigurationOverride?.playerConfiguration?.upNext?.endOffsetInSeconds
    ) : null;

  const shareSocialMediaConfiguration = brandConfigurationOverride?.playerConfiguration?.shareSocialMedia ?
    new common.ShareSocialMediaConfiguration(
      brandConfigurationOverride?.playerConfiguration?.shareSocialMedia?.facebookEnabled,
      brandConfigurationOverride?.playerConfiguration?.shareSocialMedia?.linkedInEnabled,
      brandConfigurationOverride?.playerConfiguration?.shareSocialMedia?.twitterEnabled,
    ) : null;

  const bookmarkConfiguration = brandConfigurationOverride?.playerConfiguration?.bookmark ?
    new common.BookmarkConfiguration(
      brandConfigurationOverride?.playerConfiguration?.bookmark?.enabled,
      brandConfigurationOverride?.playerConfiguration?.bookmark?.pulseIntervalInSeconds
    ) : null;

  const blackoutConfiguration = brandConfigurationOverride?.playerConfiguration?.blackout ?
    new common.BlackoutConfiguration(
      brandConfigurationOverride?.playerConfiguration?.blackout?.enabled,
      brandConfigurationOverride?.playerConfiguration?.blackout?.pulseIntervalInSeconds
    ) : null;

  const closedCaptionsConfiguration = brandConfigurationOverride?.playerConfiguration?.closedCaptions ?
    new common.ClosedCaptionsConfiguration(
      brandConfigurationOverride?.playerConfiguration?.closedCaptions?.enabled,
      brandConfigurationOverride?.playerConfiguration?.closedCaptions?.useWebVTTforVOD
    ) : null;

  const airplayConfiguration = brandConfigurationOverride?.playerConfiguration?.airplay ?
    new common.AirplayConfiguration(
      brandConfigurationOverride?.playerConfiguration?.airplay?.enabledForLive,
      brandConfigurationOverride?.playerConfiguration?.airplay?.enabledForVOD
    ) : null;

  const playerConfiguration = brandConfigurationOverride?.playerConfiguration ?
    new common.JasperPlayerConfiguration(
      brandConfigurationOverride?.playerConfiguration?.maxStreamingQuality,
      brandConfigurationOverride?.playerConfiguration?.heartbeatFrequencySeconds,
      brandConfigurationOverride?.playerConfiguration?.autoPlayEnabled,
      brandConfigurationOverride?.playerConfiguration?.infoOverlayEnabled,
      brandConfigurationOverride?.playerConfiguration?.pictureInPictureEnabled,
      brandConfigurationOverride?.playerConfiguration?.shareEnabled,
      brandConfigurationOverride?.playerConfiguration?.zoomEnabled,
      brandConfigurationOverride?.playerConfiguration?.backArrowEnabled,
      brandConfigurationOverride?.playerConfiguration?.seekForwardEnabled,
      brandConfigurationOverride?.playerConfiguration?.seekBackwardEnabled,
      brandConfigurationOverride?.playerConfiguration?.fullscreenEnabled,
      brandConfigurationOverride?.playerConfiguration?.seekbarEnabled,
      brandConfigurationOverride?.playerConfiguration?.overlayVisibilityDurationInMilliseconds,
      floatPlayerConfiguration,
      upNextConfiguration,
      brandConfigurationOverride?.playerConfiguration?.relatedContentEnabled,
      shareSocialMediaConfiguration,
      playbackSpeedConfiguration,
      bookmarkConfiguration,
      blackoutConfiguration,
      closedCaptionsConfiguration,
      airplayConfiguration
    ) : null;

  const adsTagConfiguration = brandConfigurationOverride?.adsConfiguration?.tagConfiguration ?
    new common.JasperAdsTagConfiguration(
      brandConfigurationOverride?.adsConfiguration?.tagConfiguration?.adUnit,
      brandConfigurationOverride?.adsConfiguration?.tagConfiguration?.adUnitPhone,
      brandConfigurationOverride?.adsConfiguration?.tagConfiguration?.adUnitTablet,
      brandConfigurationOverride?.adsConfiguration?.tagConfiguration?.cmsId,
      brandConfigurationOverride?.adsConfiguration?.tagConfiguration?.size,
      brandConfigurationOverride?.adsConfiguration?.tagConfiguration?.companionSizes,
      brandConfigurationOverride?.adsConfiguration?.tagConfiguration?.isOnAdManagerSchema,
      brandConfigurationOverride?.adsConfiguration?.tagConfiguration?.adOutputFormat,
      brandConfigurationOverride?.adsConfiguration?.tagConfiguration?.hasDelayedImpressions
    ) : null;

  const indexExchangeTagConfigurationCommonAttributes =
    brandConfigurationOverride?.adsConfiguration?.indexExchangeTagConfiguration
      ?.commonAttributes
      ? new common.JasperAdsIndexExchangeCommonAttributes(
          brandConfigurationOverride?.adsConfiguration?.indexExchangeTagConfiguration?.commonAttributes?.protocols,
          brandConfigurationOverride?.adsConfiguration?.indexExchangeTagConfiguration?.commonAttributes?.mimes,
          brandConfigurationOverride?.adsConfiguration?.indexExchangeTagConfiguration?.commonAttributes?.apiList,
          brandConfigurationOverride?.adsConfiguration?.indexExchangeTagConfiguration?.commonAttributes?.size,
          brandConfigurationOverride?.adsConfiguration?.indexExchangeTagConfiguration?.commonAttributes?.maxAdDuration,
          brandConfigurationOverride?.adsConfiguration?.indexExchangeTagConfiguration?.commonAttributes?.timeout,
          brandConfigurationOverride?.adsConfiguration?.indexExchangeTagConfiguration?.commonAttributes?.brandExclusion,
          brandConfigurationOverride?.adsConfiguration?.indexExchangeTagConfiguration?.commonAttributes?.durations
        )
      : null;

  const indexExchangeTagConfiguration = brandConfigurationOverride
    ?.adsConfiguration?.indexExchangeTagConfiguration
    ? new common.JasperAdsIndexExchangeTagConfiguration(
        brandConfigurationOverride?.adsConfiguration?.indexExchangeTagConfiguration?.adServerUrl,
        brandConfigurationOverride?.adsConfiguration?.indexExchangeTagConfiguration?.indexSiteId,
        brandConfigurationOverride?.adsConfiguration?.indexExchangeTagConfiguration?.indexOptimizedPodEnabled,
        indexExchangeTagConfigurationCommonAttributes,
        brandConfigurationOverride?.adsConfiguration?.indexExchangeTagConfiguration?.commonAttributesOptimizedDurations,
        brandConfigurationOverride?.adsConfiguration?.indexExchangeTagConfiguration?.implementation
      )
    : null;


  const adsUnit = brandConfigurationOverride?.adsConfiguration?.hierarchyConfiguration?.adUnit ?
    new common.JasperAdsHierarchyConfigurationAdUnit(
      brandConfigurationOverride?.adsConfiguration?.hierarchyConfiguration?.adUnit?.product,
      brandConfigurationOverride?.adsConfiguration?.hierarchyConfiguration?.adUnit?.heroBrand,
      brandConfigurationOverride?.adsConfiguration?.hierarchyConfiguration?.adUnit?.pageType,
      brandConfigurationOverride?.adsConfiguration?.hierarchyConfiguration?.adUnit?.revShare
    ) : null;

  const adsHierarchyConfiguration = brandConfigurationOverride?.adsConfiguration?.hierarchyConfiguration ?
    new common.JasperAdsHierarchyConfiguration(
      brandConfigurationOverride?.adsConfiguration?.hierarchyConfiguration?.iu,
      brandConfigurationOverride?.adsConfiguration?.hierarchyConfiguration?.enabled,
      brandConfigurationOverride?.adsConfiguration?.hierarchyConfiguration?.hierarchyLevel,
      brandConfigurationOverride?.adsConfiguration?.hierarchyConfiguration?.adTarget,
      adsUnit
    ) : null;

  const ssaiConfiguration = brandConfigurationOverride?.adsConfiguration?.ssaiConfiguration ?
    new common.JasperBrandSSAIConfiguration(
      brandConfigurationOverride?.adsConfiguration?.ssaiConfiguration?.enabledForVOD,
      brandConfigurationOverride?.adsConfiguration?.ssaiConfiguration?.sessionInitUrl,
      brandConfigurationOverride?.adsConfiguration?.ssaiConfiguration?.sessionUrl,
      brandConfigurationOverride?.adsConfiguration?.ssaiConfiguration?.useClientSideReporting,
    ) : null;

  const adsConfiguration = brandConfigurationOverride?.adsConfiguration ?
    new common.JasperAdsConfiguration(
      brandConfigurationOverride?.adsConfiguration?.enabledForVOD,
      brandConfigurationOverride?.adsConfiguration?.enabledForLive,
      brandConfigurationOverride?.adsConfiguration?.adMode,
      brandConfigurationOverride?.adsConfiguration?.volumePercentage,
      adsTagConfiguration,
      indexExchangeTagConfiguration,
      adsHierarchyConfiguration,
      brandConfigurationOverride?.adsConfiguration?.mediaLoadTimeoutMs,
      ssaiConfiguration,
      brandConfigurationOverride?.adsConfiguration?.adTagOverride
    ) : null;

  const pauseAdConfiguration = brandConfigurationOverride?.pauseAdConfiguration ?
    new common.JasperPauseAdConfiguration(
      brandConfigurationOverride?.pauseAdConfiguration?.enabled,
      brandConfigurationOverride?.pauseAdConfiguration?.width,
      brandConfigurationOverride?.pauseAdConfiguration?.height,
      brandConfigurationOverride?.pauseAdConfiguration?.adUnitId,
      brandConfigurationOverride?.pauseAdConfiguration?.minimumWidthThreshold
    ) : null;

  const chromecastConfiguration = brandConfigurationOverride?.chromecastConfiguration ?
    new common.JasperChromecastConfiguration(
      brandConfigurationOverride?.chromecastConfiguration?.enabled,
      brandConfigurationOverride?.chromecastConfiguration?.applicationId,
      brandConfigurationOverride?.chromecastConfiguration?.configPath
    ) : null;

  const brazeConfiguration = brandConfigurationOverride?.analyticsConfiguration?.braze ?
    new common.JasperBrazeAnalyticsConfiguration(
      brandConfigurationOverride?.analyticsConfiguration?.braze?.enabled,
      brandConfigurationOverride?.analyticsConfiguration?.braze?.activeForLive
    ) : null;

  const moatConfiguration = brandConfigurationOverride?.analyticsConfiguration?.moat ?
    new common.JasperMoatAnalyticsConfiguration(
      brandConfigurationOverride?.analyticsConfiguration?.moat?.enabled,
      brandConfigurationOverride?.analyticsConfiguration?.moat?.htmlPartnerCode,
      brandConfigurationOverride?.analyticsConfiguration?.moat?.sdkUrlEndpoint
    ) : null;

  const comscoreConfiguration = brandConfigurationOverride?.analyticsConfiguration?.comscore ?
    new common.JasperComscoreAnalyticsConfiguration(
      brandConfigurationOverride?.analyticsConfiguration?.comscore?.enabled,
      brandConfigurationOverride?.analyticsConfiguration?.comscore?.publisherId,
      brandConfigurationOverride?.analyticsConfiguration?.comscore?.stationCode
    ) : null;

  const mparticleConfiguration = brandConfigurationOverride?.analyticsConfiguration?.mparticle ?
    new common.JasperMParticleAnalyticsConfiguration(
      brandConfigurationOverride?.analyticsConfiguration?.mparticle?.enabled
    ) : null;

  const permutiveConfiguration = brandConfigurationOverride?.analyticsConfiguration?.permutive ?
    new common.JasperBrandPermutiveAnalyticsConfiguration(
      brandConfigurationOverride?.analyticsConfiguration?.permutive?.enabled
    ) : null;

  const newRelicConfiguration = brandConfigurationOverride?.analyticsConfiguration?.newRelic ?
    new common.JasperBrandNewRelicAnalyticsConfiguration(
      brandConfigurationOverride?.analyticsConfiguration?.newRelic?.enabled,
      brandConfigurationOverride?.analyticsConfiguration?.newRelic?.adsTrackingEnabled,
      brandConfigurationOverride?.analyticsConfiguration?.newRelic?.heartbeatFrequencyInSeconds
    ) : null;

  const analyticsConfiguration = new common.JasperAnalyticsConfiguration(
    brazeConfiguration,
    moatConfiguration,
    comscoreConfiguration,
    mparticleConfiguration,
    permutiveConfiguration,
    newRelicConfiguration
  );

  const apiConfiguration = brandConfigurationOverride?.apiConfiguration ?
    new common.JasperBrandApiConfiguration(
      brandConfigurationOverride?.apiConfiguration?.capiBaseUrl,
      brandConfigurationOverride?.apiConfiguration?.recoBaseUrl,
      brandConfigurationOverride?.apiConfiguration?.drmLicenceServerUrl,
      brandConfigurationOverride?.apiConfiguration?.usePlaybackEndpoint
    ) : null;

  return new common.JasperBrandConfiguration(
    playerConfiguration,
    adsConfiguration,
    pauseAdConfiguration,
    chromecastConfiguration,
    analyticsConfiguration,
    apiConfiguration
  );
}

export function createBrandStyle(brandStyle: Nullable<BrandStyle>) {
  const mainFont = new common.Font(
    brandStyle?.mainFont?.family,
    brandStyle?.mainFont?.weight,
    brandStyle?.mainFont?.style
  );

  const titleFont = new common.Font(
    brandStyle?.titleFont?.family,
    brandStyle?.titleFont?.weight,
    brandStyle?.titleFont?.style
  );

  const subtitleFont = new common.Font(
    brandStyle?.subtitleFont?.family,
    brandStyle?.subtitleFont?.weight,
    brandStyle?.subtitleFont?.style
  );

  return new common.JasperPlayerStylesWeb(
    brandStyle?.color,
    brandStyle?.seekBarThumbColor,
    mainFont,
    titleFont,
    subtitleFont
  );
}
