import common from 'common-jasper';

export const bootstrap = common.ca.bellmedia.jasper.web.ui.bootstrap;

export const JasperPlayer = common.ca.bellmedia.jasper.web.ui.JasperPlayer;

export const JasperLocation =
  common.ca.bellmedia.jasper.api.capi.models.JasperLocation;

const configModels = common.ca.bellmedia.jasper.api.config.models;

export const JasperBrandConfiguration = configModels.JasperBrandConfiguration;

export const JasperPlayerConfiguration =
  configModels.JasperBrandPlayerConfiguration;

export const JasperPlaybackSpeedConfiguration =
  configModels.JasperBrandPlayerPlaybackSpeedConfiguration;

export const JasperFloatPlayerConfiguration =
  configModels.JasperBrandPlayerFloatConfiguration;

export const UpNextConfiguration =
  configModels.JasperBrandPlayerUpNextConfiguration;

export const ShareSocialMediaConfiguration =
  configModels.JasperBrandPlayerShareSocialMediaConfiguration;

export const BookmarkConfiguration =
  configModels.JasperBrandPlayerBookmarkConfiguration;

export const BlackoutConfiguration =
  configModels.JasperBrandPlayerBlackoutConfiguration;

export const ClosedCaptionsConfiguration =
  configModels.JasperBrandPlayerClosedCaptionsConfiguration;

export const AirplayConfiguration =
  configModels.JasperBrandPlayerAirplayConfiguration;

export const JasperAdsConfiguration = configModels.JasperBrandAdsConfiguration;

export const JasperBrandSSAIConfiguration =
  configModels.JasperBrandSSAIConfiguration;

export const JasperPauseAdConfiguration =
  configModels.JasperBrandPauseAdConfiguration;

export const JasperChromecastConfiguration =
  configModels.JasperBrandChromecastConfiguration;

export const JasperBrandApiConfiguration =
  configModels.JasperBrandApiConfiguration;

export const JasperAnalyticsConfiguration =
  configModels.JasperBrandAnalyticsConfiguration;

export const JasperBrazeAnalyticsConfiguration =
  configModels.JasperBrandBrazeAnalyticsConfiguration;

export const JasperMoatAnalyticsConfiguration =
  configModels.JasperBrandMoatAnalyticsConfiguration;

export const JasperComscoreAnalyticsConfiguration =
  configModels.JasperBrandComscoreAnalyticsConfiguration;

export const JasperMParticleAnalyticsConfiguration =
  configModels.MParticleAnalyticsConfiguration;

export const JasperBrandPermutiveAnalyticsConfiguration =
  configModels.JasperBrandPermutiveAnalyticsConfiguration;

export const JasperBrandNewRelicAnalyticsConfiguration =
  configModels.JasperBrandNewRelicAnalyticsConfiguration;

export const JasperAdsTagConfiguration =
  configModels.JasperBrandAdsTagConfiguration;

export const JasperAdsIndexExchangeTagConfiguration =
  configModels.JasperBrandAdsIndexExchangeTagConfiguration;

export const JasperAdsIndexExchangeCommonAttributes =
  configModels.JasperBrandAdsIndexExchangeCommonAttributes;

export const JasperAdsHierarchyConfiguration =
  configModels.JasperBrandAdsHierarchyConfiguration;

export const JasperAdsHierarchyConfigurationAdUnit =
  configModels.JasperBrandAdsHierarchyConfiguration.AdUnit;

export const JasperPlayerStylesWeb =
  common.ca.bellmedia.jasper.player.models.JasperPlayerStylesWeb;

export const Font = common.ca.bellmedia.jasper.player.models.Font;

export const JasperPlayerWebError =
  common.ca.bellmedia.jasper.web.player.JasperPlayerWebError;

export const JasperPlayerGlobalConfigurator = 
  common.ca.bellmedia.jasper.web.ui.JasperPlayerGlobalConfigurator;
