import * as common from './common';
import { 
  ContentIds, 
  PlayerOptions, 
  Nullable, 
  JasperUserSettings,
  AuthTokens
} from './types';
import {
  createJasperLocation,
  createBrandConfigurationOverride,
  createBrandStyle,
} from './factories';

export interface JasperPlayerErrorEvent {
  type: string;
  code: Nullable<string>;
  platformCode: Nullable<number>;
  message: Nullable<string>;
}

export interface JasperPlayerStateChangedEvent {
  state: string;
}

export interface JasperPlayerItemChangedEvent {
  contentId: Nullable<string>;
}

export type JasperPlayerErrorEventHandler = (event: JasperPlayerErrorEvent) => boolean;

export type JasperPlayerStateChangedEventHandler = (
  event: JasperPlayerStateChangedEvent
) => void;

export type JasperPlayerItemChangedEventHandler = (
  event: JasperPlayerItemChangedEvent
) => void;

export interface JasperPlayerEventMap {
  /**
  * Triggered when an error happen within the player.
  *
  * If the handler returns `true`, no error overlay will be shown on the player. The handling of the error is delegated to you.
  */
  error: JasperPlayerErrorEvent;
  /**
  * When the state of the player changes (play, pause, etc.)
  */
  playerStateChanged: JasperPlayerStateChangedEvent;
  /**
  * When the playing item of the player changes, notifies the new contentId.
  */
  playerItemChanged: JasperPlayerItemChangedEvent;
}

export interface EventHandlers {
  error: JasperPlayerErrorEventHandler[];
  playerStateChanged: JasperPlayerStateChangedEventHandler[];
  playerItemChanged: JasperPlayerItemChangedEventHandler[];
}

/**
 * Various static methods to act against all [[JasperPlayer]] instances.
 */
 export class JasperPlayerGlobalConfigurator {
  /** @hidden */
  constructor() {}
  
  /**
   * Sets the language for metadata info, messages and buttons. It doesn't change the playback audio language.
   * 
   * @param language Must be either `'EN'` or `'FR'`
   */
  static setUILanguage(language: string) {
    common.JasperPlayerGlobalConfigurator.setUILanguage(language);
  }

  /**
   * Sets the receiver application ID required to cast on Chromecast.
   * 
   * @param receiverApplicationId 
   */
  static setReceiverApplicationId(receiverApplicationId: string) {
    common.JasperPlayerGlobalConfigurator.setReceiverApplicationId(receiverApplicationId);
  }

  /**
   * Sets the debug overlay as visible or not for debugging purpose.
   * 
   * @param enabled
   */
  static setDebugOverlay(enabled: boolean) {
    common.JasperPlayerGlobalConfigurator.setDebugOverlay(enabled);
  }

  /**
   * Sets the OAuth access and refresh tokens obtained from a successful login against BellMedia SSO.
   * 
   * @param authToken The [[AuthTokens | authentication tokens]] of the current user
   */
  static setAuthToken(authToken?: Nullable<AuthTokens>) {
    const { accessToken, refreshToken } = authToken || {};

    common.JasperPlayerGlobalConfigurator.setAuthToken(accessToken, refreshToken);
  }

  /**
   * Sets the current device identifier used for concurrency.
   * 
   * @param deviceId A unique ID (a random UUID is recommended)
   */
  static setDeviceId(deviceId?: Nullable<string>) {
    common.JasperPlayerGlobalConfigurator.setDeviceId(deviceId);
  }

  /**
   * Sets the initial user settings used by any instances of a new [[JasperPlayer]].
   * 
   * @param userSettings
   */
  static setUserSettings(userSettings: JasperUserSettings) {
    if (userSettings.closedCaptionsActive != null) {
      common.JasperPlayerGlobalConfigurator.userSettings.closedCaptionsActive = userSettings.closedCaptionsActive;
    }
    if (userSettings.playbackSpeed != null) {
      common.JasperPlayerGlobalConfigurator.userSettings.playbackSpeed = userSettings.playbackSpeed;
    }
  }    
}

export default class JasperPlayer {
  /** @hidden */
  #jasperPlayer: InstanceType<typeof common.JasperPlayer>;

  /** @hidden */
  #eventHandlers: EventHandlers = {
    error: [],
    playerStateChanged: [],
    playerItemChanged: [],
  };

  /** @hidden */
  #handleError = (error: InstanceType<typeof common.JasperPlayerWebError>) => {
    const { type, code, platformCode, message } = error;

    return this.#eventHandlers.error.reduce(
      (memo, handler) => handler({ type, code, platformCode, message }) || memo,
      false
    );
  };

  /** @hidden */
  #handlePlayerStateChangedCallback = (state: string) => {
    this.#eventHandlers.playerStateChanged.forEach((handler) =>
      handler({ state })
    );
  };

  /** @hidden */
  #handlePlayerItemChangedCallback = (contentId: Nullable<string>) => {
    this.#eventHandlers.playerItemChanged.forEach((handler) =>
      handler({ contentId })
    );
  };

  constructor(options: PlayerOptions, contentIds: ContentIds) {
    const location = createJasperLocation(options.location);
    const brandConfigurationOverride = createBrandConfigurationOverride(
      options.brandConfigurationOverride
    );
    const brandStyle = createBrandStyle(options.brandStyle);

    this.#jasperPlayer = new common.JasperPlayer(
      options.container,
      Array.isArray(contentIds) ? contentIds : [contentIds],
      options.destination,
      options.channel,
      options.brand,
      options.playbackLanguage,
      location,
      options.shareUrl,
      options.brandConfigurationEnvironment,
      brandConfigurationOverride,
      brandStyle,
      this.#handleError,
      this.#handlePlayerStateChangedCallback,
      this.#handlePlayerItemChangedCallback
    );
  }

  /**
  * Add an event listener of type [[JasperPlayerEventMap]].
  */
  on(
    type: keyof JasperPlayerEventMap,
    handler: (event: JasperPlayerEventMap[typeof type]) => any
  ) {
    this.#eventHandlers[type].push(handler);
  }

  /**
  * Add an event listener of type [[JasperPlayerEventMap]] that will only be called once then removed.
  */
  once(
    type: keyof JasperPlayerEventMap,
    handler: (event: JasperPlayerEventMap[typeof type]) => any
  ) {
    const onceHandler = (event: JasperPlayerEventMap[typeof type]) => {
      this.off(type, onceHandler);
      return handler(event);
    };

    this.on(type, onceHandler);
  }

  /**
  * Remove an event listener previously set via the [[on]] method.
  */
  off(
    type: keyof JasperPlayerEventMap,
    handler: (event: JasperPlayerEventMap[typeof type]) => any
  ) {
    const index = this.#eventHandlers[type].indexOf(handler);

    if (index !== -1) {
      this.#eventHandlers[type].splice(index, 1);
    }
  }

  /**
  * Start playback.
  */
  play() {
    this.#jasperPlayer.play();
  }

  /**
  * Pause playback.
  */
  pause() {
    this.#jasperPlayer.pause();
  }

  /**
  * Set whether player is muted or not.
  */
  setMute(isMuted: boolean) {
    this.#jasperPlayer.setMute(isMuted);
  }

  /**
  * Set player volume, must be a number between 0..100.
  */
  setVolume(volume: number) {
    this.#jasperPlayer.setVolume(volume);
  }

  /**
  * Set whether closed captions is active or not.
  */
  setClosedCaptionsActive(active: boolean) {
    this.#jasperPlayer.setClosedCaptionsActive(active);
  }

  /**
  * Seek to a specific timestamp in milliseconds.
  */
  seekTo(timestampInMilliseconds: number) {
    this.#jasperPlayer.seekTo(timestampInMilliseconds);
  }

  /**
  * Destroy this player instance.
  */
  destroy() {
    this.#jasperPlayer.destroy();
  }
}
