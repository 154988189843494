import packageJson from '../package.json';
import { bootstrap } from './common';
import { 
  default as Player, 
  JasperPlayerGlobalConfigurator as GlobalConfigurator 
} from './wrapper';

bootstrap(packageJson.version);

export default { Player, GlobalConfigurator };
